.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  font-family: "Inter", sans-serif;
}

.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  /* padding: 1rem; */
  margin: 1rem;
  max-width: 800px;
}

.CardTitle {
  font-size: min(calc(30px + 3.4vw), 70px);
  margin: 10px;
}

.CardBody {
  font-size: 1rem;
  font-weight: normal;
  font-family: "Roboto Mono";
  margin: 0 1rem;
}

.IconList {
  margin: 20px;
}

.Icon {
  padding: 10px;
}

.ColoredDetail {
  color: #499dff;
}

a {
  color: inherit;
}

#tsparticles {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  z-index: -1;
}
